@import '../theme.scss';

.fileUploaderInput {
  &.invalid {
    > .content {
      border-color: $color-red;
    }
  }

  input {
    display: none;
  }

  > .content {
    padding: 20px;
    background-color: $color-light-grey;
    border-radius: 8px;
    overflow: hidden;
    border: 1px dashed $color-dark-grey;
    grid-gap: 10px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    min-height: 120px;

    label {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.active {
      border-color: $color-black;
      background-color: $color-white;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    }

    &.uploadError {
      border-color: $color-red;

      .icon {
        .uploadError {
          display: block;
        }

        .loading,
        .upload,
        .hasFile {
          display: none;
        }
      }

      .text {
        &.uploadError {
          display: block;
        }

        &.loading,
        &.upload,
        &.hasFile {
          display: none;
        }

        .title {
          font-size: 14px;
          color: $color-red;
          font-weight: 500;
        }

        .options {
          margin-top: 10px;
          font-size: 12px;
          color: $color-red;
          display: inline-block;
        }
      }
    }

    &.loading {
      border-color: $color-black;
      background-color: $color-white;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

      .icon {
        .loading {
          display: block;
        }

        .upload,
        .uploadError,
        .hasFile {
          display: none;
        }
      }

      .text {
        &.loading {
          display: block;
        }

        &.upload,
        &.uploadError,
        &.hasFile {
          display: none;
        }

        .title {
          font-size: 14px;
          color: $color-dark-grey;
          font-weight: 500;
        }

        .options {
          margin-top: 10px;
          font-size: 12px;
          color: $color-dark-grey;
          display: inline-block;
        }
      }
    }

    &.hasFile {
      border: none;

      label {
        display: none;
      }

      .icon {
        .hasFile {
          display: block;
        }

        .loading,
        .upload,
        .uploadError {
          display: none;
        }
      }

      .text {
        &.hasFile {
          display: block;
        }

        &.loading,
        &.upload,
        &.uploadError {
          display: none;
        }

        .title {
          font-size: 14px;
          color: $color-dark-grey;
          font-weight: 500;
        }

        .options {
          margin-top: 10px;
          font-size: 12px;
          color: $color-dark-grey;
          display: inline-block;
        }
      }
    }

    .icon {
      .upload {
        display: block;
      }

      .loading,
      .uploadError,
      .hasFile {
        display: none;
      }
    }

    .text {
      &.upload {
        display: block;
      }

      &.loading,
      &.uploadError,
      &.hasFile {
        display: none;
      }

      .fileTitle {
        font-size: 14px;
        color: $color-dark-grey;
        font-weight: 500;
      }

      .options,
      .size {
        margin-top: 10px;
        font-size: 12px;
        color: $color-dark-grey;
        display: inline-block;
      }
    }
  }
}
